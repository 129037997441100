body {
  margin: 0;
  font-family: "Roboto", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.3rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.clickable {
  cursor: pointer;
}

.progress-presupuesto {
  height: 200px;
}

/*loading*/
.page-loading {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.no-suma {
  background-color: #f7a372 !important;
}

.nav-form {
  background-color: #a1a6ab;
  color: #ffffff;
}
.fullscreen {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #f4f6f9;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  /* z-index: 9999; */
}

.kanban-bottom {
  text-align: right;
}
.page-loading div.loading {
  display: block;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider-label {
  font-weight: 400 !important;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #3d9970;
}
input:focus + .slider {
  box-shadow: 0 0 1px #3d9970;
}
input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.imgButton {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.imgUpButton {
  position: absolute;
  top: 5px;
  right: 5px;
}

div.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 100000000;
  background: rgba(128, 127, 126, 0.85);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.mh-200 {
  min-height: 200px !important;
}
.mh-250 {
  min-height: 250px !important;
}
.mh-282 {
  min-height: 282px !important;
}

.mh-300 {
  min-height: 300px !important;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
.bg-olive-disabled {
  background-color: #84bca3;
  color: #fff;
}

.text-olive-disabled {
  color: #84bca3;
}

.text-primary-disabled {
  color: #17a2b8;
}

.bg-danger-disabled {
  background-color: #e77e89;
  color: #fff;
}

.text-danger-disabled {
  color: #e77e89;
}

.text-pink-disabled {
  color: #ef85b5;
}

.text-lightblue-disabled {
  color: #83b6d2;
}

.text-navy-disabled {
  color: #5b6f82;
}

.bg-navy-disabled {
  background-color: #5b6f82;
  color: #fff;
}

.appname {
  font-size: 1.7rem;
}

.brand {
  width: 100%;
}

.brandname {
  color: #fff;
  font-size: 10px;
}

.invalid {
  border: 1px solid red !important;
  border-radius: 4px !important;
}

.invalid + label {
  color: red !important;
}

.centered-header thead th {
  vertical-align: middle;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  border: 0;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle.btn-lg {
  width: 44px;
  height: 44px;
  padding: 9px 8px;
  border: 0;
  border-radius: 22px;
  font-size: 20px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 7px 2px;
  border: 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
.btn-circle.btn-sm {
  width: 20px;
  height: 20px;
  padding: 4px 1px;
  border: 0;
  border-radius: 10px;
  text-align: center;
  font-size: 8px;
  line-height: 1.24;
}

.btn-transparent {
  background-color: transparent;
}
.btn-transparent:hover {
  background-color: lightgray;
  -webkit-animation: scale-up-center 0.03s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.03s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-11-2 10:12:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* input:-webkit-autofill::first-line {
  color: red;
  font-family: "Rubik"; 
  font-weight: bold;
} */

.icons {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: auto;
  max-height: 250px;
}
.icon {
  width: 48px;
  margin: 16px;
}

.top-right {
  position: absolute;
  /* scale: (1); */
  transition: 0.3s ease-out;
}

.top-right:hover {
  font-size: 1.7em;
  /* scale: (1.5); */
  transition: 0s;
  /* .2s ease-out; */
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-file-upload:hover {
  background-color: lightgray;
}

.custom-file-upload-name {
  align-items: center;
  margin-left: 5px;
  margin-block: auto;
}

.form-label-group {
  margin-bottom: 1rem;
}

.login-input {
  font-size: 1.5rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 1rem 0.7rem;
  color: rgb(32, 33, 36);
  font-weight: 400;
  font-family: Roboto;
  transition: 0.1s ease-out;
  height: auto;
}

.invalid {
  border: 1px solid red !important;
  border-radius: 4px !important;
}

.invalid + label {
  color: red !important;
}

.invalidLabel {
  color: #dc3545 !important;
}

.login-label {
  position: absolute;
  font-family: Roboto;
  font-size: 1.4rem;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: rgb(95, 99, 104);
  padding: 0;
  margin: 0;
  font-weight: 400 !important;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.avatarContainer {
  display: relative;
  width: 150px;
  height: 150px;
}

.bread {
  color: #007bff;
  cursor: pointer;
}

.bread :hover {
  color: #005ab2;
}

.camera {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  top: 0px;
  color: rgba(245, 245, 245, 0.9);
  background-color: rgba(230, 230, 230, 0.4);
  border-radius: 100%;
  z-index: 999;
  visibility: hidden;
}

.title-block {
  float: left;
}

.title-block .title,
.title-block .subtitle,
.title-block .comment {
  display: block;
}

.title-block .title {
  font-size: 16px;
  font-weight: 600;
  margin-top: -1px;
}

.title-block .subtitle {
  color: #6c757d;
  font-size: 13px;
  margin-top: -3px;
}

.title-block.title-block-sm img {
  width: 1.875rem;
  height: 1.875rem;
}

.title-block.title-block-sm .title,
.title-block.title-block-sm .subtitle,
.title-block.title-block-sm .comment {
  margin-left: 40px;
}

.title-block.title-block-sm .title {
  font-size: 14px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

.group-box {
  display: block;
  width: 100%;
  /* height: calc((2.25rem + 2px) * var(--factor)); */
  /* padding: calc(0.375rem * var(--factor)) calc(0.75rem * var(--factor)); */
  padding: 1rem 0.2rem 0.2rem 0.2rem;
  /* font-size: calc(1rem * var(--factor)); */
  /* font-weight: 400; */
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.group-box-label {
  position: absolute;
  font-family: Roboto;
  font-size: 1.2rem;
  transform: translateY(-50%);
  background-color: white;
  color: rgb(95, 99, 104);
  padding: 0;
  margin: 0;
  font-weight: 400 !important;
  /* transition: 0.1s ease-out; */
  /* transform-origin: left top; */
  pointer-events: none;

  /* color: rgb(26, 115, 232); */
  padding: 0 4px;
  top: 0;
  left: 1rem;
  /* transform: translateY(-38%) scale(0.8) !important;   */
}

.avatarContainer:hover > .camera {
  visibility: visible;
}

.invisible {
  display: none;
}

.form-control-xs {
  height: calc((1.5552rem + 2px) * var(--factor));
  padding: calc(0.2624rem * var(--factor)) calc(0.5248rem * var(--factor));
  font-size: calc(0.7rem * var(--factor));
  line-height: 1.2;
  border-radius: 0.16rem;
}

.floating-form-label-textarea {
  top: 1.2rem;
}

.floating-form-label-input-lg {
  top: 2.5rem;
}

.floating-form-label-input {
  top: 1.7rem;
}

.floating-form-label-input-sm {
  top: 1.4rem;
}

.floating-form-label-input-xs {
  top: 1.12rem;
}

.floating-form-label-lg {
  position: absolute;
  font-family: Roboto;
  font-size: calc(1.2rem * var(--factor));
  left: 1rem;
  transform: translateY(-1.53rem);
  background-color: white;
  color: rgb(95, 99, 104);
  padding: 0;
  margin: 0;
  font-weight: 400 !important;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.floating-form-label {
  position: absolute;
  font-family: Roboto;
  font-size: calc(1rem * var(--factor));
  left: 1rem;
  transform: translateY(-50%);
  background-color: white;
  color: rgb(95, 99, 104);
  padding: 0;
  margin: 0;
  font-weight: 400 !important;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.floating-form-label-xs {
  position: absolute;
  font-family: Roboto;
  font-size: calc(0.7rem * var(--factor));
  left: 0.8rem;
  transform: translateY(-50%);
  background-color: white;
  color: rgb(95, 99, 104);
  padding: 0;
  margin: 0;
  font-weight: 400 !important;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.floating-form-label-sm {
  position: absolute;
  font-family: Roboto;
  font-size: calc(0.875rem * var(--factor));
  left: 1rem;
  transform: translateY(-50%);
  background-color: white;
  color: rgb(95, 99, 104);
  padding: 0;
  margin: 0;
  font-weight: 400 !important;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-label-sm {
  padding-top: calc(0.2rem + 1px);
  padding-bottom: calc(0.2rem + 1px);
  font-size: 0.7rem;
  line-height: 1.2;
}

.form-label-group > input:focus + label,
.form-label-group > textarea:focus + label {
  /* color: #dc3545; */
  color: rgb(26, 115, 232);
  padding: 0 4px;
  top: 0;
  left: 4px;
  transform: translateY(-38%) scale(0.8) !important;
}

.form-label-group > input:not(:placeholder-shown) + label,
.form-label-group > textarea:not(:placeholder-shown) + label {
  top: 0;
  left: 4px;
  padding: 0 4px;
  color: rgb(26, 115, 232);
  transform: translateY(-38%) scale(0.8) !important;
  font-weight: 400;
}

.form-label-group > input:not(:focus)::placeholder,
.form-label-group > textarea:not(:focus)::placeholder {
  font-weight: 400;
  opacity: 0;
}

.form-label-group > input:not(:focus):not(:placeholder-shown) + label,
.form-label-group > textarea:not(:focus):not(:placeholder-shown) + label {
  padding: 0 4px;
  color: rgb(95, 99, 104);
  font-weight: 400;
}

.form-label-group > input:not(:focus):placeholder-shown + label,
.form-label-group > textarea:not(:focus):placeholder-shown + label {
  padding: 0 4px;
  /* color: #dc3545; */
  font-weight: 400;
}

.form-label-group input::-webkit-input-placeholder,
.form-label-group textarea::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder,
.form-label-group textarea:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder,
.form-label-group textarea::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder,
.form-label-group textarea::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder,
.form-label-group textarea::placeholder {
  color: transparent;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 30px;
  height: 17px;
  padding: 0;
  border-radius: 34px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 14px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}
