:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 1.3rem;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#login {
  box-sizing: content-box;
  background: url("../../assets/main-background.png") center center no-repeat;
  background-size: cover;
  background-position: fixed;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  color: #d1d1d1;
  display: relative;
}

/* HERO */
#login-intro {
  padding: 18vh 15vh;
  color: white;
}

#login-intro .logo {
  width: 128px;
  margin-bottom: 32px;
}

#login-intro .title {
  font-size: 42px;
  font-weight: 400;
  line-height: 1;
}

#login-intro .description {
  padding-top: 16px;
  font-size: 16px;
  max-width: 450px;
  font-weight: 400;
}

#login-form-wrapper {
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  width: 400px;
  overflow: auto;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}

/* FORM */
#login-form {
  padding: 128px 48px 48px 48px;
}

#login-form .logo {
  display: none;
}

#login-form .controls {
  padding: 80px 0 0 0;
}

#login-form .title {
  color: black;
  font-size: 21px;
  text-align: center;
  margin-bottom: 2rem;
}

#login-form .invalid-login {
  margin-top: 2rem;
  text-align: center;
  color: red;
  font-weight: 800;
  min-height: 1.9rem;
}

#login-form .description {
  padding-top: 8px;
}

.form-label-group {
  position: relative;
  margin-bottom: 1.1rem;
}

#btnLogin {
  font-weight: 700;
  padding: 0.75rem 1rem;
  margin-top: 3rem;
  border-radius: 2px;
  font-size: 1.6rem !important;
}

.remember-forgot-password {
  display: flex;
  font-size: 1.3rem;
  margin-top: 1rem;
  color: rgb(95, 99, 104);
  align-items: center;
  justify-content: space-between;
}

.remember-me-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remember-me {
  margin: 0 5px 0 0;
}

.forgot-password {
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px auto 24px auto;
  width: 250px;
  color: rgb(95, 99, 104);
  font-size: 1.2rem;
  font-weight: 600;
}
.text {
  margin-right: 8px;
}

.link {
}

/* ANIMATIONS */
.slide-in-blurred-left {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    0.3s both;
  animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.3s both;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    0.3s both;
  animation: scale-up-center 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s both;
}

.slide-left {
  -webkit-animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s
    both;
  animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
}

@media screen and (max-width: 767px) {
  #login {
    background: white;
  }

  #login-intro {
    display: none;
  }

  #login-form {
    padding: 10% 5% 5%;
  }

  #login-form .logo {
    display: block;
    width: 30%;
    margin: 5% 35% 10%;
  }

  #login-form .logo img {
    width: 100%;
  }

  #login-form-wrapper {
    width: 100vw;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-9-22 19:36:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-9-22 19:40:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-9-23 8:41:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
