.dropzone {
  position: relative;
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #000;
}

.dropzone-bg {
  position: absolute;
  background-size: cover;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}

.accept {
  border-color: #84bca3 !important;
}

.reject {
  border-color: #e77e89 !important;
}
